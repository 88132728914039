import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdPieChart } from 'react-icons/md';

import './styles.css';
import logo from '../../assets/logoHorizontal.png';
import { PieChart } from 'react-minimal-pie-chart';
import api from '../../services/api';
import loading from '../../assets/loading.gif';
import { exportToExcel } from 'react-json-to-excel';
import { format } from 'date-fns';


const Relatorios = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [answers, setAnswers] = useState([]);
  const [campaign, setCampaign] = useState({});

  useEffect(() => {
    loadingCampaign()
  }, [])

  async function loadingCampaign() {
    await api.get(`/reports-campaign/${id}`).then((response) => {
      let answers = []
      response.data.answers_question.map((item) => {
        answers.push({
          "E-mail participante": item.client.email,
          "Nome do participante": item.client.name,
          "Códio do produto": item.name,
          "Nome do produto": item.code,
          "Goste = Amei": item.liked == "amei" ? 1 : 0,
          "Goste = Talvez":  item.liked == "talvez" ? 1 : 0,
          "Goste = Não curti":  item.liked == "nao" ? 1 : 0,
          "Valor Sugerido = Barato": item.liked == "nao" ? 0 : item.suggested_value == "barato" ? 1 : 0,
          "Valor Sugerido = Ok":  item.liked == "nao" ? 0 : item.suggested_value == "bom" ? 1 : 0,
          "Valor Sugerido = Caro":  item.liked == "nao" ? 0 : item.suggested_value == "caro" ? 1 : 0,
          "Outras Cores = Sim":  item.liked == "nao" ? 0 : item.other_colours ? 1 : 0,
          "Outras Cores = Não":  item.liked == "nao" ? 0 : item.other_colours ? 0 : 1,
          "Cor = Amarelo":  item.liked == "nao" ? 0 : item.amarelo ? 1 : 0,
          "Cor = Azul":  item.liked == "nao" ? 0 : item.azul ? 1 : 0,
          "Cor = Bege":  item.liked == "nao" ? 0 : item.bege ? 1 : 0,
          "Cor = Branco":  item.liked == "nao" ? 0 : item.branco ? 1 : 0,
          "Cor = Cinza":  item.liked == "nao" ? 0 : item.cinza ? 1 : 0,
          "Cor = Dourado":  item.liked == "nao" ? 0 : item.dourado ? 1 : 0,
          "Cor = Estampado":  item.liked == "nao" ? 0 : item.estampado ? 1 : 0,
          "Cor = Marinho":  item.liked == "nao" ? 0 : item.marinho ? 1 : 0,
          "Cor = Marrom":  item.liked == "nao" ? 0 : item.marrom ? 1 : 0,
          "Cor = Preto":  item.liked == "nao" ? 0 : item.preto ? 1 : 0,
          "Cor = Rosa":  item.liked == "nao" ? 0 : item.rosa ? 1 : 0,
          "Cor = Roxo":  item.liked == "nao" ? 0 : item.roxo ? 1 : 0,
          "Cor = Verde":  item.liked == "nao" ? 0 : item.verde ? 1 : 0,
          "Cor = Vermelho":  item.liked == "nao" ? 0 : item.vermelho ? 1 : 0,
        })
      })
      console.log(answers)
      setAnswers(answers)
      setCampaign(response.data)
    })
    setIsLoading(false)
  }

  return (
    <div className='container'>
      <div className='header'>
        <img src={logo} />
        <p onClick={() => {
          navigate("/")
        }}>Voltar</p>
      </div>
      <div className='row'>

      {
        isLoading ? (
          <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
            <img style={{ width: 80, height: 70, marginTop: 35 }} src={loading} />
          </div>
        ) : (
            <>
              <div className='content' style={{ background: "#EFEFEF", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", paddingBottom: 80 }}>
                <h1 className='title' style={{ width: "calc(100% - 200px)" }}>Relatório - {format(new Date(campaign.created_at), "dd/MM/yyyy")} </h1>
                <button className='buttonExport' onClick={() => {
                  exportToExcel(answers, `relatorio campanha ${format(new Date(campaign.created_at), "dd/MM/yyyy")}`)
                }}>
                  <MdPieChart style={{marginRight: 8}} color="#000"/>
                  exportar respostas
                </button>
                <div className='chart'>
                  <div>
                    <p>Produtos</p>
                    <h1>{campaign.questions.length}</h1>
                  </div>
                  <span>Gostou?</span>
                  <PieChart
                    lineWidth="75"
                    style={{width: "150px", height: "150px", marginTop: 25}}
                    data={[
                      { title: 'One', value: campaign.liked_talvez, color: '#72B3FF' },
                      { title: 'Two', value: campaign.liked_nao, color: '#FF7288' },
                      { title: 'Three', value: campaign.liked_amei, color: '#58CB53' },
                    ]}
                  />
                </div>
                <div className='chart'>
                  <div>
                    <p>Participantes</p>
                      <h1>{campaign.clients}</h1>
                  </div>
                  <span>Valores Sugeridos</span>
                  <PieChart
                    lineWidth="75"
                    style={{width: "150px", height: "150px", marginTop: 25}}
                    data={[
                      { title: 'One', value: campaign.suggested_value_bom, color: '#72B3FF' },
                      { title: 'Two', value: campaign.suggested_value_caro, color: '#FF7288' },
                      { title: 'Three', value: campaign.suggested_value_barato, color: '#58CB53' },
                    ]}
                  />
                </div>
                <div className='chart'>
                  <div>
                    <p>Total de votos</p>
                    <h1>{campaign.answers_question.length*3-campaign.answers_question.filter((item)=> item.liked == "nao").length*2}</h1>
                  </div>
                  <span>Gostaria desse item em outras cores?</span>
                  <PieChart
                    lineWidth="75"
                    style={{width: "150px", height: "150px", marginTop: 25}}
                    data={[
                      { title: 'Two', value: campaign.other_colours_sim, color: '#FF7288' },
                      { title: 'Three', value: campaign.other_colours_nao, color: '#58CB53' },
                    ]}
                  />
                </div>
              </div>
              <div className='content' style={{ background: "#EFEFEF", paddingBottom: 80 }}>
                      <div className='campanha'>
                        <p style={{width: 100, borderLeftWidth: 0}}>Foto</p>
                        <p style={{width: 100}}>Código</p>
                        <p style={{width: 100}}>Preço Sugerido</p>
                        <p style={{width: 150}}>Gostei</p>
                        <p style={{width: 150}}>Preço</p>
                        <p style={{width: 150}}>Cores</p>
                      </div>
                {
                    campaign.questions.map((item, index) => {
                    if(index >= (page * 3 - 3) && index < page * 3 )
                    return (
                      <div className='campanha' style={index == 2 ? { borderBottomWidth: 0 } : {}}>
                        <img src={`https://cdnmatch.fillity.com.br/${id}/${item.photos.split(",")[0]}`} style={{width: 80, margin: 10, height: 120, background: "#fff", borderWidth: 1, borderStyle: "solid", borderColor: "#000"}} />
                        <p style={{ width: 100 }}>{item.id.substr(0,13)}</p>
                        <p style={{ width: 100 }}>{item.suggested_value}</p>
                        <div className='graphs'>
                          <div className='graph'>
                            <div style={{height: item.liked_amei ? item.liked_amei*100/(item.liked_amei + item.liked_talvez + item.liked_nao) : 1 , background: "#58CB53"}}></div>
                            <p style={{color: "#58CB53"}}>{item.liked_amei}</p>
                          </div>
                          <div className='graph'>
                            <div style={{height: item.liked_talvez ? item.liked_talvez*100/(item.liked_amei + item.liked_talvez + item.liked_nao) : 1 , background: "#72B3FF"}}></div>
                            <p style={{color: "#72B3FF"}}>{item.liked_talvez}</p>
                          </div>
                          <div className='graph'>
                            <div style={{height: item.liked_nao ? item.liked_nao*100/(item.liked_amei + item.liked_talvez + item.liked_nao) : 1 , background: "#FF7288"}}></div>
                            <p style={{color: "#FF7288"}}>{item.liked_nao}</p>
                          </div>
                        </div>
                        <div className='graphs'>
                          <div className='graph'>
                            <div style={{height: item.suggested_value_barato ? item.suggested_value_barato*100/(item.suggested_value_barato + item.suggested_value_bom + item.suggested_value_caro) : 1, background: "#58CB53"}}></div>
                            <p style={{color: "#58CB53"}}>{item.suggested_value_barato}</p>
                          </div>
                          <div className='graph'>
                            <div style={{height: item.suggested_value_bom ? item.suggested_value_bom*100/(item.suggested_value_barato + item.suggested_value_bom + item.suggested_value_caro) : 1, background: "#72B3FF"}}></div>
                            <p style={{color: "#72B3FF"}}>{item.suggested_value_bom}</p>
                          </div>
                          <div className='graph'>
                            <div style={{height: item.suggested_value_caro ?  item.suggested_value_caro*100/(item.suggested_value_barato + item.suggested_value_bom + item.suggested_value_caro) : 1, background: "#FF7288"}}></div>
                            <p style={{color: "#FF7288"}}>{item.suggested_value_caro}</p>
                          </div>
                        </div>
                        <div className='graphs'>
                          <div className='graph'>
                            <div style={{height: item.other_colours_sim ? item.other_colours_sim*100/(item.other_colours_sim + item.other_colours_nao) : 1, background: "#58CB53"}}></div>
                            <p style={{color: "#58CB53"}}>{item.other_colours_sim}</p>
                          </div>
                          <div className='graph'>
                            <div style={{height: item.other_colours_nao ? item.other_colours_nao*100/(item.other_colours_sim + item.other_colours_nao) : 1, background: "#FF7288"}}></div>
                            <p style={{ color: "#FF7288" }}>{item.other_colours_nao}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              <div className='pagination'>
                {
                  page > 1 && (
                    <button onClick={() => {
                      setPage(page-1)
                    }}>
                      ANTERIOR
                    </button>
                  )
                }
                <p>{page} DE {campaign.questions.length%3 ? (campaign.questions.length-campaign.questions.length%3)/3+1 : campaign.questions.length/3}</p>
                {
                  (page < (campaign.questions.length%3 ? (campaign.questions.length-campaign.questions.length%3)/3+1 : campaign.questions.length/3)) && (
                    <button onClick={() => {
                      setPage(page+1)
                    }}>
                      PROXIMO
                    </button>
                  )
                }
              </div>
              </div>
            </>
          )
        }
        
      </div>
    </div>
  );
};

export default Relatorios;
