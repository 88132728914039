import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';

const PrivateRoute = () => {
  const { user } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user]);

  return user ?
    (
      <>
        <header>
        </header>
        <Outlet />
      </>
    ) : (<></>);
};

export default PrivateRoute;
