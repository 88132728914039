import React from 'react';
import { Routes, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import RouterWrapper from './RouterWrapper';
import Login from '../pages/Login';
import Home from '../pages/Home';
import Relatorios from '../pages/Relatorios';
import Campanha from '../pages/Campanha';

const Router = () => (
  <Routes>
    <Route exact path="/login" element={<RouterWrapper />}>
      <Route exact path="/login" element={<Login />} />
    </Route>
    <Route exact path="/" element={<PrivateRoute />}>
      <Route exact path="/" element={<Home />} />
    </Route>
    <Route exact path="/relatorio/:id" element={<PrivateRoute />}>
      <Route exact path="/relatorio/:id" element={<Relatorios />} />
    </Route>
    <Route exact path="/campanha/:id" element={<RouterWrapper />}>
      <Route exact path="/campanha/:id" element={<Campanha />} />
    </Route>
  </Routes>
);

export default Router;
