import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdCircle, MdFileUpload, MdImageSearch, MdLink, MdPauseCircle, MdPieChart, MdPlayCircle } from 'react-icons/md';
import XLSX from "xlsx";
import './styles.css';
import logo from '../../assets/logoHorizontal.png';
import loading from '../../assets/loading.gif';
import star from '../../assets/star.png';
import { useAuth } from '../../hooks/AuthContext';
import { Button, Modal } from 'antd';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import copy from 'copy-to-clipboard';

const Home = () => {
  const navigate = useNavigate()
  const [campaigns, setCampaigns] = useState(null)
  const [campaign, setCampaign] = useState(null)
  const [page, setPage] = useState(0);
  const [questions, setQuestions] = useState(0);
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [visibleModalDetails, setVisibleModalDetails] = useState(false);
  const [isLoadingUp, setIsLoadingUp] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    loadingCampaign()
  }, [page])

  const handleDragOver = (event) => {
      event.preventDefault();
      setDragging(true);
  };

  const handleDragEnter = (event) => {
      event.preventDefault();
      setDragging(true);
  };

  const handleDragLeave = (event) => {
      event.preventDefault();
      setDragging(false);
  };

  async function loadingCampaign() {
    await api.get(`/campaigns?page=${page}`).then((response) => {
      setCampaigns(response.data)
    }).catch(async ({response}) => {
      if (response.data.message == "Usuário não existe") {
        await signOut()
        navigate("/login")
      }
    })
    setIsLoading(false)
  }

  function handleFile(file) {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result;

      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true
      });

      /* Get first worksheet */
      //This const only read the first excelSheet
      const wsname = wb.SheetNames[0];

      //This const reads the excelData
      const ws = wb.Sheets[wsname];

      /* Converting the data into array object */
      const data = XLSX.utils.sheet_to_json(ws);
      let questions = []
      let error = false
      data.map((item) => {
        if (item.nome_produto && item.codigo_produto && item.fotos && item.valor_produto) {
          questions.push({
            name: item.nome_produto,
            code: item.codigo_produto,
            suggested_value: item.valor_produto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
            photos: item.fotos
            })
        } else {
          error = true
        }
      })
      if (error) {
        toast.error("Formato da planilha não é compátivel")
      } else {
        setQuestions(questions.length)
        setCampaign({name: file.name, questions})
        uploadCampaign(file.name, questions)
      }
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }

  }

  async function uploadCampaign(name, questions) {
    if (questions.length == 0) {
      toast.warn("Nenhum produto encontrado")
    }else{
      await api.post("/campaign", {
        name: name,
        questions: questions
      }).then((response) => {
        setCampaign(response.data)
      })
    }
  }

  async function uploadPhoto(photos, campaign_id) {
    let photosC = [...photos]
      photosC.map(async (file, index) => {
        if (!file.upload && !file.error) {
          const data = new FormData();
          data.append('file', file, file.name);
          await api.post(`/photo-campaign/${campaign_id}`, data).then(() => {
              file.upload = true
          }).catch(() => {
              file.upload = true
              file.error = true
          })
          setPhotos((photos) => {
             photos[index] = file
              return [...photos]
          })
        }
    })
  }

  async function activeCampaign(id, index) {
    await api.put(`/active-campaign/${id}`).then((response) => {
      let campaignsC = { ...campaigns}
      let campaignsEditC = [...campaignsC.campaigns]
      campaignsEditC[index] = response.data
      campaignsC.campaigns = campaignsEditC
      setCampaigns(campaignsC)
      if (response.data.active) {
        toast.success("Campanha iniciada com sucesso")
      } else {
        toast.success("Campanha pausada com sucesso")
      }
    })
  }

  async function publishedCampaign() {
    setIsLoadingUp(true)
    await api.put(`/published-campaign/${campaign.id}`).then(() => {
        setVisibleModal(true)
    })
    setIsLoadingUp(false)
  }

  const { signOut } = useAuth();

  return (
    <div className='container'>
      <div className='header'>
        <img src={logo} />
        <p onClick={async () => {
          await signOut()
          navigate("/login")
        }}>Sair</p>
      </div>
      <div className='row'>
        {
          isLoading ? (
            <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
              <img style={{ width: 80, height: 70, marginTop: 35 }} src={loading} />
            </div>
          ) : (
              <>
              <div className='content' style={{ background: "#EFEFEF", paddingBottom: 80}}>
                  <h1 className='title'>{campaigns.campaignsLength} Campanhas publicadas</h1>
                  {
                    campaigns.campaigns.map((item, index) => {
                      return (
                        <div className='campanhaHome' style={index == 2 ? {borderBottomWidth: 0} : {}}>
                          <p style={{ border: "none" }}>{format(new Date(item.created_at), "dd/MM/yyyy")}</p>
                          <p style={{border: "none"}}>{item.questions.length} produtos</p>
                          <p style={{border: "none"}}>{item.answers_question.length*3-item.answers_question.filter((data)=> data.liked == "nao").length*2} votos</p>
                          <button className='buttonHome' onClick={() => {
                            copy(`https://match.fillity.com.br/campanha/${item.id}`)
                            toast.success("Link da campanha copiado com sucesso")
                          }}>
                            <MdLink style={{marginRight: 8}} color="#000"/>
                            copiar link
                          </button>
                          <button className={item.active ? "active" : "inative"}>
                            <MdCircle style={{marginRight: 8}} color={item.active ? "#58CB53" : "#EF534F"} />
                            {item.active ? "aberto" : "fechado"}
                          </button>
                          <button onClick={() => {
                            navigate(`/relatorio/${item.id}`)
                          }} className='buttonHome'>
                            <MdPieChart style={{marginRight: 8}} color="#000"/>
                            relatório
                          </button>
                          {
                            item.active ? (
                              <MdPauseCircle size={40} color="#EF534F" style={{ cursor: "pointer" }}
                            onClick={() => activeCampaign(item.id, index)}/>
                            ): (
                              <MdPlayCircle size={40} color="#32BFA6" style={{ cursor: "pointer" }}
                              onClick={() => activeCampaign(item.id, index)}/>
                            )
                          }
                        </div>
                      )
                    })
                  }
        <div className='pagination'>
            {
              page != 0 && (
                 <button onClick={()=>{
                  setPage(page-1)
                 }}> 
                  ANTERIOR
                </button>
              )
            }
            <p>{page+1} DE {campaigns.campaignsLength%10 ? parseInt(campaigns.campaignsLength/10)+1 : campaigns.campaignsLength/10 || 1}</p>
            {((campaigns.campaignsLength%10 ? parseInt(campaigns.campaignsLength/10)+1 : campaigns.campaignsLength/10 || 1) > 1 &&
            (campaigns.campaignsLength%10 ? parseInt(campaigns.campaignsLength/10)+1 : campaigns.campaignsLength/10 || 1) != (page+1))&& (
              <button onClick={()=>{
                setPage(page+1)
               }}> 
                PROXIMO
              </button>
            )}
        </div>
        </div>
        <div className='contentEdit' style={{padding: "0 25px"}}>
          <div className='contentFiles'>
            <h1 className='title'>Nova Campanha</h1>
            <div className='rowFiles'>
              <div
                  key="drop"
                  className='files'
                  onDragOver={handleDragOver}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  onDrop={(event)=>{
                    event.preventDefault();
                    if (campaign) {
                      toast.warn("Planilha já enviada")
                    } else {
                      if (event.dataTransfer.files) {
                        setDragging(false);
                        const files = event.dataTransfer.files[0];
                        handleFile(files)
                      }
                    }
                  }}
                  style={{
                      background: dragging ? '#eee' : 'transparent',
                      borderColor: dragging ? '#555' : '#aaa',
                  }}
              >
                        <label for={`files`} className={"fileInput"} style={{ cursor: campaign ? "initial" : "pointer" }}>
                          <input id={`files`} disabled={campaign} style={{ display: "none" }} type="file" onChange={(event) => {
                            if (event.target.files) {
                              const files = event.target.files[0];
                              handleFile(files)
                            }
                          }}
                      />
                  </label>
                  <MdFileUpload size={80} style={{marginRight: 15}} />
                  <p>{campaign ? campaign.name : "Arraste ou selecione sua planilha"}</p>

                </div>
                  {campaign && (
                        <div
                          key="drop"
                          className='files'
                          onDragOver={handleDragOver}
                          onDragEnter={handleDragEnter}
                          onDragLeave={handleDragLeave}
                          onDrop={(event) => {
                            event.preventDefault();
                            setDragging(false);
                            let photosUp = []
                            Object.values(event.dataTransfer.files).map((item) => {
                                item.upload = false
                                item.error = false
                                photosUp.push(item)
                            })
                            let photosC = [...photos]
                            photosC = [...photosC, ...photosUp]
                            setPhotos(photosC)
                            uploadPhoto(photosC, campaign.id)
                          }}
                          style={{
                            background: dragging ? '#eee' : 'transparent',
                            borderColor: dragging ? '#555' : '#aaa',
                          }}
                        >
                            <label for={`photos`} className={"fileInput"}>
                            <input id={`photos`} type="file" multiple="multiple" onChange={(event) => {
                              let photosUp = []
                              Object.values(event.target.files).map((item) => {
                                  item.upload = false
                                  item.error = false
                                  photosUp.push(item)
                              })
                              let photosC = [...photos]
                              photosC = [...photosC, ...photosUp]
                              setPhotos(photosC)
                              uploadPhoto(photosC, campaign.id)
                            }}/>
                            </label>
                            <MdImageSearch size={80} style={{marginRight: 15}} />
                            <p>Arraste ou selecione suas imagens</p>
                        </div>
                          )}
                          
                      </div>
                  </div>
                      { !!photos.length && (
                        <div className='uploadInfos'>
                          <span>
                            Upload de imagens
                          </span>
                          <h4 onClick={()=>{
                            setVisibleModalDetails(true)
                          }}>
                            ( ver detalhes )
                          </h4>
                          <p>
                          {photos.length} imagens importadas com sucesso
                          </p>
                        </div>
                      )}
                      { campaign && (
                        <div className='uploadInfos'>
                          <span>
                            Upload da planilha
                          </span>
                          <p>
                            {questions} produtos importados com sucesso
                          </p>
                        </div>
                      )}
                      
                      <Button loading={isLoadingUp} disabled={photos.length == 0 || photos.filter((item)=> {return item.upload == false}) != 0} onClick={async () => {
                        publishedCampaign()
                      }}>PUBLICAR</Button>
                  </div>
              </>
          )
        }
        
      </div>
      <Modal
        width={450}
        open={visibleModal}
        style={{marginTop: 250}}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        closable={false}
        onCancel={() => {
          navigate(`/relatorio/${campaign.id}`)
        }}
      >
        <div className='star'>
          <img src={star} />
          <h1 className='title' style={{marginBottom: 10}}>Campanha criada</h1>
          <button className='buttonBorder' onClick={() => {
            copy(`https://match.fillity.com.br/campanha/${campaign.id}`)
            toast.success("Link da campanha copiado com sucesso")
          }}>
            <MdLink size={20} style={{marginRight: 8}} color="#000"/>
            copiar link
          </button>
        </div>
      </Modal>
      <Modal
        width={500}
        open={visibleModalDetails}
        style={{marginTop: 50}}
        okButtonProps={{ style: { display: "none" } }}
        closable={false}
        onCancel={() => {
          setVisibleModalDetails(false)
        }}
      >
        <div className='details'>
          {photos.map((item) => {
            return (
              <div className='detailsImg'>
                <p>{item.name}</p>
                <p style={{textAlign: "right", paddingRight: 15}}>{!item.upload ? "enviando" : item.error ? "erro de upload" : "enviado com sucesso"}</p>
              </div>
            )
          })}
        </div>
      </Modal>
    </div>
  );
};

export default Home;
