import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Checkbox } from 'antd';
import './styles.css';
import logo from '../../assets/logoVertical.png';
import star from '../../assets/star.png';
import loading from '../../assets/loading.gif';
import { MdCircle } from 'react-icons/md';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { format } from 'date-fns';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const Campanha = () => {
  const {id} = useParams()
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [like, setLike] = useState("")
  const [price, setPrice] = useState("")
  const [isColor, setIsColor] = useState("")
  const [question, setQuestion] = useState(null)
  const [message, setMessage] = useState("")
  const [client, setClient] = useState({})
  const [colors, setColors] = useState([])
  const [etapa, setEtapa] = useState(0)
  const [update, setUpdate] = useState(0)
  const [isLoading, setIsLoading] = useState(true);
  const [campaign, setCampaign] = useState({});

  useEffect(() => {
    loadingCampaign()
  }, [])

  async function loadingCampaign() {
    await api.get(`/campaign/${id}`).then((response) => {
      setCampaign(response.data)
      if (!response.data.active) {
        setEtapa(4)
      }
    }).catch(({ response }) => {
      setEtapa(4)
      if (response) {
        if (response.data) {
          if (response.data.message) {
            setMessage(response.data.message)
            toast.warn(response.data.message)
          } else {
            setMessage("Erro Interno. verifique sua conexão e tente novamente")
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          setMessage("Erro Interno. verifique sua conexão e tente novamente")
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        setMessage("Erro Interno. verifique sua conexão e tente novamente")
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
    setIsLoading(false)
  }

  async function getQuestion(client, init) {
    await api.get(`/question/${id}?client_id=${client.id}`).then((response) => {
      const question = response.data
      if (question) {
        setQuestion(question)
        setEtapa(2)
      } else {
        if (init) {
          setMessage("Você já participou dessa campanha!")
        }
        setEtapa(3)
      }
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
    setTimeout(() => {
      setIsLoading(false)
    }, 250)
  }

  async function checkCampaign() {
    await api.post(`/check-campaign/${id}`, {
      email: email
    }).then(async (response) => {
      const client = response.data
      if (client) {
        setClient(client)
        await getQuestion(client , true)
      } else {
        setEtapa(1)
      }
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
    setIsLoading(false)
  }

  async function initCampaign() {
    await api.post(`/init-campaign/${id}`, {
      email: email,
      name: name
    }).then(async (response) => {
      const client = response.data
      setClient(client)
      await getQuestion(client)
      setEtapa(2)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
    setIsLoading(false)
  }

  useEffect(() => {
    if (update) {
      createAnswer()
    }
  },[update])

  async function createAnswer() {
    setIsLoading(true)
    await api.post(`/answer/${question.id}`, {
      liked: like,
      suggested_value: price,
      other_colours: isColor || "nao",
      colors: JSON.stringify(colors),
      client_id: client.id,
      campaign_id: campaign.id
    }).then(async () => {
      setLike("")
      setPrice("")
      setIsColor("")
      setColors([])
      await getQuestion(client)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
    setTimeout(() => {
      setIsLoading(false)
    }, 250)
  }


  const cores = [
    "Amarelo", "Azul", "Bege",
    "Branco", "Cinza", "Dourado",
    "Estampado", "Laranja", "Marinho",
    "Marrom", "Preto", "Rosa",
    "Roxo", "Verde", "Vermelho",
  ]

  const values = {
     "amei": <MdCircle style={{marginLeft: 5}} color={"#58CB53"} />,
     "talvez": <MdCircle style={{marginLeft: 5}} color={"#72B3FF"} />,
     "nao": <MdCircle style={{marginLeft: 5}} color={"#FF7288"} />,
     "barato": <MdCircle style={{marginLeft: 5}} color={"#58CB53"} />,
     "bom": <MdCircle style={{marginLeft: 5}} color={"#72B3FF"} />,
     "caro": <MdCircle style={{marginLeft: 5}} color={"#FF7288"} />,
     "sim": <MdCircle style={{marginLeft: 5}} color={"#58CB53"} />
  }

  return (
    <div className='containerCampanha' style={{ padding: etapa == 2 ? 0 : 15 }}>
      {isLoading && (
          <div className='contentCampanha' style={{justifyContent: "center"}}>
            <img style={{ width: 80, height: 70, marginTop: 35 }} src={loading} />
          </div>
      )}
      {
       ((etapa == 0 || etapa == 1) && !isLoading) && (
          <div className='contentCampanha'>
            <img src={logo} className='logoCampanha' />
            <h1 className='title'>Campanha {format(new Date(campaign.created_at), "dd/MM/yyyy")}</h1>
            <div className='input'>
              <input
                type="text"
                placeholder="Digite seu e-mail para continuar"
                onChange={(text) => { setEmail(text.target.value) }}
                value={email}
              />
            </div>
            {(etapa == 1 && !isLoading) && (
              <div className='input'>
                <input
                  type="text"
                  placeholder="Digite seu nome para continuar"
                  onChange={(text) => { setName(text.target.value) }}
                  value={name}
                />
              </div>
            )}
            <button className='button' loading={loading} onClick={async () => {
              if (etapa == 0) {
                await checkCampaign()
              } else {
                await initCampaign()
              }
            }}>Entrar</button>
          </div>
        )}
        {(etapa == 2 && !isLoading) && (
      <div className='contentBorder'>
          <div className='contentForm'>
            <Carousel
              className='carousel'
              showArrows={true}
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              width={"100%"}
            >
              {
                question.photos.split(",").map((item) => {
                  return (
                    <img src={`https://cdnmatch.fillity.com.br/${id}/${item.replaceAll(" ", "")}`} className='sliderImage' />
                  )
                })
              }
             </Carousel>
            <div>
                <span style={like ? {borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "#fff"} : {}}>
                  Gostou?
                  {like && values[like]}
                </span>
                {!like && (
                  <div className='rowForm'>
                    <button className='rowFormButton' onClick={()=>{
                      setLike("amei")
                    }}>
                      <MdCircle style={{marginRight: 8}} color={"#58CB53"} />
                      Amei
                    </button>
                    <button className='rowFormButton' onClick={()=>{
                      setLike("talvez")
                    }}>
                      <MdCircle style={{marginRight: 8}} color={"#72B3FF"} />
                      Talvez
                    </button>
                    <button className='rowFormButton' onClick={()=>{
                      setLike("nao")
                      setUpdate(update+1)
                    }}>
                      <MdCircle style={{marginRight: 8}} color={"#FF7288"} />
                      Não curti
                    </button>
                  </div>
                )}
                {
                  like && (
                    <>
                      <span style={price ? {borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "#fff"} : {}}>
                        Valor Sugerido: {question.suggested_value} ?
                        {price && values[price]}
                      </span>
                      {!price && (
                        <div className='rowForm'>
                          <button className='rowFormButton' onClick={()=>{
                            setPrice("barato")
                          }}>
                            <MdCircle style={{marginRight: 8}} color={"#58CB53"} />
                            Barato
                          </button>
                          <button className='rowFormButton' onClick={()=>{
                            setPrice("bom")
                          }}>
                            <MdCircle style={{marginRight: 8}} color={"#72B3FF"} />
                            Tá bom!
                          </button>
                          <button className='rowFormButton' onClick={()=>{
                            setPrice("caro")
                          }}>
                            <MdCircle style={{marginRight: 8}} color={"#FF7288"} />
                            Caro
                          </button>
                        </div>
                      )}
                    </>
                  )
                }
                {
                  price && (
                    <>
                      <span>
                        Gostaria dese item em outras cores?
                        {isColor && values[isColor]}
                      </span>
                      {!isColor && (
                        <div className='rowForm'>
                          <button className='rowFormButton' onClick={()=>{
                            setIsColor("sim")
                          }}>
                            <MdCircle style={{marginRight: 8}} color={"#58CB53"} />
                            Sim
                          </button>
                          <button className='rowFormButton' onClick={()=>{
                            setIsColor("nao")
                            setUpdate(update+1)
                          }}>
                            <MdCircle style={{marginRight: 8}} color={"#FF7288"} />
                            Não
                          </button>
                        </div>
                      )}
                    </>
                  )
                }
                {
                  isColor == "sim" && (
                    <>
                      <p>Escolha até 3 tonalidades</p>
                      <div className='rowCores'> 
                         { cores.map((item)=>{
                           return (
                              <div className='cor'>
                               <Checkbox checked={colors.filter((data) => { return item == data }).length != 0} onChange={() => {
                                 if (colors.filter((data) => { return item == data }).length != 0) {
                                    setColors(colors.filter((data) => { return item != data }))
                                 } else {
                                   let colorsC = [...colors]
                                   if (colorsC.length >= 3) {
                                      toast.warn("Selecione apenas 3 cores")
                                   } else {
                                      colorsC.push(item)
                                      setColors(colorsC)
                                   }
                                  }
                                }}>
                                  {item}
                                </Checkbox>
                              </div>
                            )
                         })}
                        <button onClick={() => {
                          if (!colors.length) {
                            toast.warn("Selecione pelo menos uma cor")
                          } else {
                            setUpdate(update+1)
                          }
                        }}>
                          PROXIMO
                        </button>
                      </div>
                    </>
                  )
                }
              </div> 
            </div>
          </div>
        )
      }
      
      {
       (etapa == 3 && !isLoading) && (
          <div className='contentCampanha'>
            <img src={logo} className='logoCampanha' />
            <h1 className='title'>Campanha {format(new Date(campaign.created_at), "dd/MM/yyyy")}</h1>
            <div className='sucess'>
              <img src={star} />
              {message ? <p>{message}</p> : <>Concluido<br/>Obrigado!</>}
            </div>
          </div>
        )}
      {(etapa == 4 && !isLoading) && (
          <>
          {
            message ? (
              <div className='contentCampanha' style={{justifyContent: "center"}}>
                <h3 className='titleEnd'>
                {message}
                </h3>
              </div>
           ) : (
            <div className='contentCampanha' style={{justifyContent: "center"}}>
              <h3 className='titleEnd'>
              Campanha {format(new Date(campaign.created_at), "dd/MM/yyyy")} 
              </h3>
              <h3 className='titleEnd'>
              já foi encerrada
              </h3>
            </div>
            )}
          </>
      )}
    </div>
  );
};

export default Campanha;
