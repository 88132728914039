import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@fillitymatch:token');
    const user = localStorage.getItem('@fillitymatch:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  /**
   * SignIn
   */
  const signIn = useCallback(async ({ email, password }) => {
    await api.post('/session', { email, password }).then((response) => {
      const { user, token } = response.data;

      localStorage.setItem('@fillitymatch:token', token);
      localStorage.setItem('@fillitymatch:user', JSON.stringify(user));

      api.defaults.headers = {
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*"
      }
      
      api.defaults.headers.authorization = `Bearer ${token}`;

      setData({ token, user });
      
      return true
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
      return false
    })

  }, []);

  /**
   * SignOut
   */
  const signOut = useCallback(() => {
    localStorage.removeItem('@fillitymatch:token');
    localStorage.removeItem('@fillitymatch:user');

    setData({});
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const contenxt = useContext(AuthContext);
  if (!contenxt) {
    throw new Error('useAuth must be used with an AuthProvider');
  }
  return contenxt;
}

export { AuthProvider, useAuth };
