import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import logo from '../../assets/logoVertical.png';
import { useAuth } from '../../hooks/AuthContext';
import { Button } from 'antd';

const Login = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false);


  const { signIn, user } = useAuth();

  useEffect(() => {
    if (user) {
      navigate("/")
    } 
  },[user])

  async function login(email, password) {
    setLoading(true)
    await signIn({ email, password })
    setLoading(false)
  }

  return (
    <div className='containerLogin'>
      <div className='contentLoginV' style={{background: "#EFEFEF"}}>
        <img src={logo} alt="logo" />
      </div>
      <div className='contentLoginV'>
        <div className='contentLogin'>
          <div className='input' style={{marginBottom: 10}}>
            <input
                styleInput={{background: "#fff", paddingLeft: 10}}
                type="email"
                placeholder={"E-mail"}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.keyCode === 13) {
                    login(email, password)
                  }
                }}
                onChange={(text)=>{
                  setEmail(text.target.value)
                }}
                value={email}
              />
          </div>  
          <div className='input'>
            <input
                type="password"
                placeholder="Senha"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.keyCode === 13) {
                    login(email, password)
                  }
                }}
                onChange={(text)=>{
                  setPassword(text.target.value)
                }}
                value={password}
              />
          </div>  
          <Button className='button' loading={loading} onClick={async () => {
              login(email, password)
          }}>Entrar</Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
